import { Box, CircularProgress } from '@mui/material';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { APIAuthClient } from '../../Api/ApiAuthClient';


export interface IProtectedToolRouteProps {
    children: React.ReactNode;
    // route: string;
}

export default function ProtectedToolRoute(props: IProtectedToolRouteProps) {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoaded, setIsLoaded] = React.useState(false);
    // const 
    React.useEffect(() => {
        new APIAuthClient().IsAuthed().then((r) => {
            if (r === true) {
                setIsLoaded(true);
                // setLoggedIn(true);
                //@ts-ignore
                if (window.chrome.webview)
                    //@ts-ignore
                    window.chrome.webview.postMessage({ MessageType: 'start' });
            }
            else navigate('/login/' + encodeURIComponent(location.pathname.substring(1)));
        }).catch((err) => {
            console.log(err);
            navigate('/login/' + encodeURIComponent(location.pathname.substring(1)));
        });
    }, [])
    if (isLoaded)
        return (
            <Box>
                {props.children}
            </Box>
        );
    else return <CircularProgress />
}
