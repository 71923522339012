import * as React from 'react';
import { useNavigate, useParams } from 'react-router';
import { useGateApi } from '../Api/Hooks/useGateApi';
import { AuthApiGateClient } from '../Api/Hooks/ApiServerHooks';
import { CircularProgress } from '@mui/material';

export interface ILoginToolGateRedirectProps {
}

export default function LoginToolGateRedirect(props: ILoginToolGateRedirectProps) {
    const params = useParams();
    const navigate = useNavigate();
    const { unauthorizedApi } = useGateApi(AuthApiGateClient);
    const createParams = (search: string) => {
        if (!search.includes('+')) {
            return search;
        }
        return search.split('+').join('/');
    }
    React.useEffect(() => {
        if (unauthorizedApi && params.redirect && params.session) {
            unauthorizedApi.getPluginLoginSessionTokens(params.session).then((data) => {
                localStorage.setItem('token', data.accessToken!);
                localStorage.setItem('refreshToken', data.refreshToken!);
                localStorage.setItem('token_expires', data.expires?.toString()!);
                console.log(params.redirect, 'redirect');
                console.log(decodeURIComponent(params.redirect ?? ''), 'redirect');
                if (params.redirect)
                    navigate('/' + decodeURIComponent(params.redirect));
            })

        }
    }, [params, unauthorizedApi, navigate]);
    return (
        <div>
            <CircularProgress />
        </div>
    );
}
