import axios, { AxiosInstance } from "axios";
// import delay from "@/shared/delay";
// import { SingletonRefreshClient } from "@/api/abstraction/SingletonRefreshClient";
import { useCallback, useMemo } from "react";
// import { useTrimbleApi } from "./useTrimbleApi";


export function useGateApi<T>(ApiClass: new (url: string, axios: AxiosInstance) => T) {
    // const { currentLanguage } = useLocalization(); // use your hook
    //   const { user, token, project } = useTrimbleApi();
    const GetApiAddress = useCallback((): string => {
        console.log('GATE API URL', process.env.REACT_APP_GATE_API_URL);
        return process.env.REACT_APP_GATE_API_URL!;
    }, []);
    const Unauthorized = useCallback((): T => {
        const axiosApiInstance = axios.create();

        // Request interceptor for API calls
        axiosApiInstance.interceptors.request.use(
            async config => {
                config.headers = {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'TimeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
                }
                return config;
            },
            error => {
                Promise.reject(error)
            });

        const apiInstance = new ApiClass(GetApiAddress(), axiosApiInstance);
        if (!apiInstance) {
            throw new Error("Failed to create Unauthorized API instance");
        }
        return apiInstance;
    }, [ApiClass, GetApiAddress]);
    const AuthorizedForTenants = useCallback((): T => {
        const axiosApiInstance = axios.create();
        const token = localStorage.getItem('temp_tenant_token');
        // Request interceptor for API calls
        axiosApiInstance.interceptors.request.use(
            async config => {
                config.headers = {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'TimeZone': Intl.DateTimeFormat().resolvedOptions().timeZone,
                    'Authorization': 'Bearer ' + token
                }
                return config;
            },
            error => {
                Promise.reject(error)
            });

        const apiInstance = new ApiClass(GetApiAddress(), axiosApiInstance);
        if (!apiInstance) {
            throw new Error("Failed to create Unauthorized API instance");
        }
        return apiInstance;
    }, [ApiClass, GetApiAddress]);
    const unauthorizedApi = useMemo(() => {
        let apiInstance = Unauthorized();
        return apiInstance;
    }, [Unauthorized]);
    const authorizedApiForTenants = useMemo(() => {
        let apiInstance = AuthorizedForTenants();
        return apiInstance;
    }, [AuthorizedForTenants]);

    return { unauthorizedApi, Unauthorized, authorizedApiForTenants };
}