import React, { useEffect } from 'react'
import { useNavigate } from 'react-router';
import { Box, CircularProgress } from '@mui/material';

interface SlopePluginProps {
    isLogged?: boolean;
}

export default function SlopePlugin(props: Readonly<SlopePluginProps>) {
    const navigate = useNavigate();

    useEffect(() => {
        if (props.isLogged) {
            const acc = localStorage.getItem('token');
            const refr = localStorage.getItem('refreshToken');
            //@ts-ignore
            if (window.chrome.webview)
                //@ts-ignore
                window.chrome.webview.postMessage({ MessageType: 'login', AccessToken: acc, RefreshToken: refr });
        }
        else {
            navigate('/login/' + encodeURIComponent('slopeplugin/logged'));
        }
    }, [props.isLogged]);
    
    return (
        <Box>
            <CircularProgress />
        </Box>
    )
}
