import { useMsal } from '@azure/msal-react';
import { Switch, useTheme } from '@mui/material';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import CurveSolverUpperMenu from '../CurveSolver/CurveSolverUpperMenu';
import { UpperMenuType } from '../CurveSolver/Enums/UpperMenuTypes';
import { ProjectDTO } from '../Api/ApiServer';
import { ApiBaseClient } from '../Api/ApiBaseClient';
import { ApiDataClient } from '../Api/ApiDataClient';
import ProjectItemScene from './ProjectItemScene';
import CreateProjectDialog from './Components/CreateProjectDialog';
import UpdateProjectDialog from './Components/UpdateProjectDialog';
import { useValidatorStore } from '../CurveSolver/Stores/ValidatorStore';
import { ValidatorsEnum } from '../CurveSolver/Enums/ValidatorsEnum';
import DeleteDialog from '../Shared/DeleteDialog';

interface ProjectsSceneProps {
    onlySlope?: boolean;
    onlyCurve?: boolean;

}

export default function ProjectsScene(props: ProjectsSceneProps) {
    const { onlyCurve, onlySlope}= props;
    const { instance } = useMsal();
    const theme = useTheme();
    // console.log(theme);
    const [createOpen, setCreateOpen] = React.useState(false);
    const [updateOpen, setUpdateOpen] = React.useState(false);
    const [deleteProjectId, setDeleteProjectId] = React.useState<number | undefined>();
    const [projects, setProjects] = React.useState<ProjectDTO[]>();
    const [updatingProject, setUpdatingProject] = React.useState<ProjectDTO>();

    const { initValidator, setValidator, validator } = useValidatorStore();

    const navigate = useNavigate();

    useEffect(() => {
        reloadProjects();
        if (!validator === undefined)
            initValidator();
    }, []);

    function reloadProjects() {
        new ApiDataClient().GetProjects().then((v) => {
            setProjects(v.projects);
        });
    }

    async function deleteProject(projectId: number) {
        setDeleteProjectId(projectId);
        /* new ApiDataClient().DeleteProject(projectId).then((v) => {
            reloadProjects();
        }); */
    }

    async function updateProject(projectId: number) {
        setUpdatingProject(projects?.find(p => p.id === projectId));
        setUpdateOpen(true);
    }

    const handleChange = () => {
        setValidator(ValidatorsEnum.None);
        navigate('/validators');
    }

    return (
        <div style={{ background: '#F5F5F5', display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CurveSolverUpperMenu
                navigationEnabled
                showChangeTenantDialog={() => { }}
                onAddProject={() => { setCreateOpen(true); }}
                menuType={UpperMenuType.Projects} />
            <div style={{ background: '#F5F5F5', position: 'relative', top: '80px', display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ color: theme.palette.primary.main, fontSize: '22px', paddingLeft: '32px', fontWeight: 'bold' }}>
                        Projekty
                    </div>
                    {/* <div style={{ display: 'flex', color: theme.palette.primary.main, fontSize: '14px', paddingRight: '30px' }}>
                        <div style={{ marginTop: '6px', color: 'black' }}>{'Validátory'}</div>
                        <Switch sx={{ marginTop: '5px' }} size='small' onChange={handleChange} checked />
                        <div style={{ marginTop: '6px', fontWeight: 'bold' }}>{'Projekty'}</div>
                    </div> */}
                </div>

                <div style={{ marginTop: '-16px', display: 'flex', flexWrap: 'wrap', height: '100%', marginLeft: '16px' }}>
                    {projects && projects.map((v, i) => {
                        return <ProjectItemScene onlyCurve={onlyCurve} onlySlope={onlySlope} key={i} project={v} onDeleteProject={deleteProject} onUpdateProject={updateProject} />
                    })
                    }
                </div>
            </div>
            <CreateProjectDialog open={createOpen} onClose={() => { setCreateOpen(false); }} onRefresh={() => { reloadProjects(); }} />
            <UpdateProjectDialog project={updatingProject} open={updateOpen} onClose={() => { setUpdateOpen(false); setUpdatingProject(undefined); }} onRefresh={() => { reloadProjects(); }} />
            <DeleteDialog open={deleteProjectId !== undefined} onClose={() => { setDeleteProjectId(undefined) }} onDeleteAgree={
                        () => {
                            if (deleteProjectId)
                                new ApiDataClient().DeleteProject(deleteProjectId).then((v) => {
                                    reloadProjects();
                                    setDeleteProjectId(undefined)
                                });
                        }
                    } />
        </div>)
}
