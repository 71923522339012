import React, { useEffect } from 'react'
import ProjectSelect from '../Projects/Components/ProjectSelect';
import RoutesSelect from '../Routes/Components/RoutesSelect';
import { Button, Switch, TextField, useTheme } from '@mui/material';
import { ProjectDTO, RouteDTO } from '../Api/ApiServer';
import Login from '../Auth/Login';
import { ApiDataClient } from '../Api/ApiDataClient';
import { useNavigate } from 'react-router';
import { usePluginStore } from './Stores/PluginStore';

declare global {
    interface Window {
        sendCurveValidationsData: (data: any) => void;
        // loginExternal: (accessToken: string, refreshToken: string, expiresAt: Date) => void;
    }
}

export default function CurveSolverExternalImporter() {
    window.sendCurveValidationsData = sendCurveValidationsData;
    const {setPlugin, fromPlugin} = usePluginStore();
    function sendCurveValidationsData(data: any) {
        // let d = JSON.parse(data);
        // if(d.name) 
        // new APIAuthClient().Login(data.UserName, data.Password).then((res) => {
        console.log('anoanoano');
        setPlugin(true);
        setData(data);
    }


    const [data, setData] = React.useState<any>();
    const [loggedIn, setLoggedIn] = React.useState(false);
    const [toExisting, setToExisting] = React.useState(false);
    const [replace, setReplace] = React.useState(true);
    const [routeName, setRouteName] = React.useState('');
    const [selectedProject, setSelectedProject] = React.useState<ProjectDTO | null>(null);
    const [routes, setRouted] = React.useState<RouteDTO[]>([]);
    const [selectedRoute, setSelectedRoute] = React.useState<RouteDTO | null>(null);
    const navigate = useNavigate();
    const theme = useTheme();
    
    // useEffect(() => {
    //     //@ts-ignore
    //     if (window.chrome.webview)
    //         //@ts-ignore
    //         window.chrome.webview.postMessage({ MessageType: 'start' });

    //     new ApiDataClient().IsLoggedIn().then((r) => {
    //         if (r === true) {
    //             setLoggedIn(true);
    //         }
    //         else setLoggedIn(false);

    //     }).catch((err) => {
    //         setLoggedIn(false);
    //     });
    //     // if (window.chrome.webview)

    // }, []);
    const loadRoutes = (projectID: number) => {
        // if (selectedProject)
        new ApiDataClient().GetRoutes(projectID).then((res) => {
            if (res !== undefined && res.routes !== undefined)
                setRouted(res.routes);
        });
    }

    // if (loggedIn)
        return (
            <div>
                <div style={{ padding: '10px' }}>
                    <ProjectSelect selectedProjectInput={selectedProject} onChange={(project) => { if (project !== null) loadRoutes(project.id!); else setRouted([]); setSelectedProject(project); setSelectedRoute(null); }} />
                </div>
                
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', fontSize: '14px', paddingRight: '30px', marginLeft: '24px' }}>
                        <div style={{ marginTop: '6px', fontWeight: toExisting ? 'normal' : 'bold', color: toExisting ? 'black' :  theme.palette.primary.main }}>{'Nový import'}</div>
                        <Switch sx={{ marginTop: '5px' }} size='small' checked={toExisting} onChange={(e, checked) =>{ setToExisting(checked) }} />
                        <div style={{ marginTop: '6px', fontWeight: toExisting ? 'bold' : 'normal', color: toExisting ? theme.palette.primary.main : 'black'  }}>{'Do existující'}</div>                    
                    </div>
                    {toExisting && <div style={{ display: 'flex', fontSize: '14px', paddingRight: '30px', marginLeft: '32px' }}>
                        <div style={{ marginTop: '6px', fontWeight: replace ? 'normal' : 'bold', color: replace ? 'black' : theme.palette.primary.main }}>{'Přidat'}</div>
                        <Switch sx={{ marginTop: '5px' }} size='small' checked={replace} onChange={(e, checked) =>{ setReplace(checked) }} />
                        <div style={{ marginTop: '6px', fontWeight: replace ? 'bold' : 'normal', color: replace ? theme.palette.primary.main : 'black' }}>{'Nahradit'}</div>
                    </div>}
                </div>
                {!toExisting && <div style={{ margin: '10px', padding: '10px'  }}>
                    <TextField
                        id="outlined-basic"
                        size='small'
                        label={'Název trasy'}
                        className="text-field"
                        sx={{ marginBottom: '12px', marginLeft: '10px', marginRight: '32px', width: 'calc(100% - 20px)', '& .MuiInputBase-root': { paddingLeft: '16px' } }}
                        value={routeName}
                        onChange={(e) => { setRouteName(e.currentTarget.value) }}
                        variant="outlined" />
                </div>}
                {toExisting && <div style={{ margin: '10px' }}>
                    <RoutesSelect routesInput={routes} selectedRoute={selectedRoute} onChange={(e) => { setSelectedRoute(e) }} />
                </div>}

                

                <Button sx={{ margin: '10px', position: 'absolute', bottom: '20px', width: '78%', left: '11%' }} variant='contained' disabled={(toExisting && (selectedRoute === null || selectedProject === null)) || (!toExisting && selectedProject === null) || (!toExisting && routeName === '')} onClick={() => {
                    console.log('karel je:',  selectedProject!.id!, selectedRoute ? selectedRoute.id : undefined, toExisting, replace);
                    new ApiDataClient().ImportMultiCurves(data, selectedProject!.id!, selectedRoute ? selectedRoute.id : undefined, toExisting, replace, routeName).then((routeId) => { navigate('/solverMain/' + routeId) });
                }}>EXPORTOVAT OBLOUKY</Button>
            </div>
        )
    // else return <Login onLogin={() => setLoggedIn(true)} />
}
