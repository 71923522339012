import { Button, Divider, Popover, Tooltip, useTheme } from '@mui/material';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { ProjectDTO, ProjectType } from '../Api/ApiServer';
import { EditRoadOutlined, Insights, MoreVert, RuleFolderOutlined } from '@mui/icons-material';
import { useValidatorStore } from '../CurveSolver/Stores/ValidatorStore';
import { ValidatorsEnum } from '../CurveSolver/Enums/ValidatorsEnum';

interface ProjectItemSceneProps {
    project: ProjectDTO;
    onDeleteProject(projectId: number): void;
    onUpdateProject(projectId: number): void;
    onlySlope?: boolean;
    onlyCurve?: boolean;
}

export default function ProjectItemScene(props: ProjectItemSceneProps) {
    const { project, onDeleteProject, onUpdateProject } = props;
    const theme = useTheme();
    // console.log(theme);
    const [name, setName] = React.useState("");

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const openUserMenu = Boolean(anchorEl);
    const [open, setOpen] = React.useState(false);
    const id = open ? 'simple-popover' : undefined;

    const { initValidator, setValidator, validator } = useValidatorStore();

    useEffect(() => {
        if (validator === undefined)
            initValidator();
    }, []);

    useEffect(() => {

    }, [project]);

    const handleUserMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);

    };

    const redirectToBimValidator = () => {
        window.location.replace(process.env.REACT_APP_BIMVALIDATOR_URL + '/router/' + localStorage.getItem('token'))
    }

    return (
        <div style={{
            height: /* validator === ValidatorsEnum.None ? '350px' : */ '250px', width: '340px', margin: '16px', marginTop: '32px', marginBottom: 0, border: '0px solid gray', background: /* v.hasRight ? theme.palette.primary.main : */ 'white',
            borderRadius: '4px', boxShadow: 'rgb(0 0 0 / 20%) 0px 4px 4px'
        }}>
            <div style={{ display: 'box', height: '100%', width: '100%', marginTop: '5px', cursor: validator === ValidatorsEnum.None ? 'default' : 'pointer' }} onClick={() => { if (validator !== ValidatorsEnum.None) navigate('/routes/' + project.id) }}>
                <div style={{ display: 'flex', marginTop: '5px', marginBottom: '0px', justifyContent: 'space-between' }}>
                    <div style={{ color: theme.palette.primary.main, marginLeft: '16px', fontWeight: 'bold', marginTop: '4px' }}>
                        {project.name}
                    </div>
                    <div >
                        <Button sx={{ display: 'box', height: '100%', width: '20px' }} size='small' variant='text' onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleUserMenuClick(e); }}><MoreVert /></Button>
                    </div>
                    <Popover
                        id={id}
                        sx={{ zIndex: 50002 }}
                        open={openUserMenu}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <div className="popup-content-div" >
                            <div>
                                <Button color="secondary" sx={{ width: "150px", paddingRight: "12px", justifyContent: "flex-start", fontSize: '10px' }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); if (project && project.id) { onUpdateProject(project.id!); handleClose(); } }}>
                                    Upravit projekt
                                </Button>
                            </div>
                            <div>
                                <Button color="secondary" sx={{ width: "150px", paddingRight: "12px", justifyContent: "flex-start", fontSize: '10px' }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); if (project && project.id) { onDeleteProject(project.id!); handleClose(); } }}>
                                    Smazat projekt
                                </Button>
                            </div>
                            {/* <Divider sx={{ bgcolor: "primary.main" }} /> */}

                            {/* <Button color="secondary" onClick={logout} sx={{ width: "120px", paddingRight: "12px", justifyContent: "flex-start" }} >
                                {t('login.logout')}
                            </Button> */}

                        </div>
                    </Popover>
                </div>
                <Divider sx={{ marginTop: '2px', marginBottom: 'auto', bgcolor: 'black' }} />
                <div style={{ height: 200 }}>
                    {project.type === ProjectType.Road && <img style={{ height: '100%', maxWidth: '100%', objectFit: 'contain' }} src={process.env.PUBLIC_URL + "/road.png"} />}
                    {project.type === ProjectType.Railway && <img style={{ height: '100%', maxWidth: '100%', objectFit: 'contain' }} src={process.env.PUBLIC_URL + "/rail.png"} />}
                    {project.type === ProjectType.RoadBridge && <img style={{ height: '100%', maxWidth: '100%', objectFit: 'contain' }} src={process.env.PUBLIC_URL + "/roadBridge.png"} />}
                    {project.type === ProjectType.RailwayBridge && <img style={{ height: '100%', maxWidth: '100%', objectFit: 'contain' }} src={process.env.PUBLIC_URL + "/railBridge.png"} />}
                    {project.type !== ProjectType.Road && project.type !== ProjectType.Railway && project.type !== ProjectType.RoadBridge && project.type !== ProjectType.RailwayBridge &&
                        <img style={{ height: '100%', maxWidth: '100%', objectFit: 'contain' }} src={process.env.PUBLIC_URL + "/noProjectPicture.png"} />
                    }
                </div>
                <div style={{ display: 'flex', marginTop: '-55px', background: 'white', justifyContent: 'left', paddingLeft: 6 }}>
                    {props.onlySlope !== true && <div style={{ margin: 4, background: 'white', borderRadius: '4px', boxShadow: 'rgb(0 0 0 / 20%) 0px 4px 4px' }}>
                        <Tooltip title={'GPK Validátor'}>
                            <Button sx={{ height: '50px', minWidth: '80px', borderRadius: '4px', border: '1px solid', background: 'white' }} onClick={(e) => { e.stopPropagation(); setValidator(ValidatorsEnum.CurveSolver); navigate('/routes/' + project.id) }} size='small' variant='outlined' startIcon={<img style={{ height: '32px', width: '32px', objectFit: 'contain', background: 'white' }} src={process.env.PUBLIC_URL + "/gpk_icon.png"} />}>GPK</Button>
                        </Tooltip>
                    </div>}
                    {props.onlyCurve !== true && <div style={{ margin: 4, background: 'white', borderRadius: '4px', boxShadow: 'rgb(0 0 0 / 20%) 0px 4px 4px' }}>
                        <Tooltip title={'SlopeGuard'}>
                            <Button sx={{ height: '50px', minWidth: '80px', borderRadius: '4px', border: '1px solid', background: 'white' }} onClick={(e) => { e.stopPropagation(); setValidator(ValidatorsEnum.SlopeGuard); navigate('/routes/' + project.id) }} size='small' variant='outlined' startIcon={<EditRoadOutlined style={{ height: '32px', width: '32px', objectFit: 'contain', background: 'white' }} />}>SG</Button>
                            {/* <Button sx={{ height: '50px',minWidth: '80px', borderRadius: '4px', border: '1px solid', background: 'white' }} onClick={(e) =>{e.stopPropagation(); setValidator(ValidatorsEnum.SlopeGuard); navigate('/routes/' + project.id)}} size='small' variant='outlined' startIcon={<img style={{ height: '32px', width: '32px', objectFit: 'contain', background: 'white'}} src={process.env.PUBLIC_URL + "/gpk_icon.png"}/>}>SG</Button> */}
                        </Tooltip>
                    </div>}
                    
                </div>

            </div>
        </div>
    );
}
