import { useMsal } from '@azure/msal-react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import CurveSolverUpperMenu from '../CurveSolver/CurveSolverUpperMenu';
import { UpperMenuType } from '../CurveSolver/Enums/UpperMenuTypes';
import { BaseLineDTO, CorridorDTO, ProjectDTO, RouteDTO } from '../Api/ApiServer';
import { ApiBaseClient } from '../Api/ApiBaseClient';
import { ApiDataClient } from '../Api/ApiDataClient';
import CreateProjectDialog from '../Projects/Components/CreateProjectDialog';
import UpdateProjectDialog from '../Projects/Components/UpdateProjectDialog';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import CreateRouteDialog from './Components/CreateRouteDialog';
import { Add, Delete, Edit, ExpandMore, NavigateNext } from '@mui/icons-material';
import UpdateRouteDialog from './Components/UpdateRouteDialog';
import NavigationPanel, { NavigationItem } from '../Shared/NavigationPanel';
import { useValidatorStore } from '../CurveSolver/Stores/ValidatorStore';
import { ValidatorsEnum } from '../CurveSolver/Enums/ValidatorsEnum';
import TreeView, { flattenTree, INode, ITreeViewOnNodeSelectProps, NodeId } from "react-accessible-treeview";

interface RoutesSceneProps {
    projectId: number;
}

enum TreeLevel {
    Corridor = 0,
    BaseLine = 1
}

export default function RoutesScene(props: RoutesSceneProps) {
    const { projectId } = props;
    const theme = useTheme();
    // console.log(theme);
    const [createOpen, setCreateOpen] = React.useState(false);
    const [updateOpen, setUpdateOpen] = React.useState(false);
    const [routes, setRoutes] = React.useState<RouteDTO[]>();
    const [updatingRoute, setUpdatingRoute] = React.useState<RouteDTO>();

    const navigate = useNavigate();
    const params = useParams();
    const { validator } = useValidatorStore();

    useEffect(() => {
        reloadRoutes();        
    }, []);

    useEffect(() => {
        reloadRoutes();
    }, [params]);

    function reloadRoutes() {
        if (projectId) {
            new ApiDataClient().GetRoutes(projectId).then((v) => {
                setRoutes(v.routes);
            });
        }
        else {
            setRoutes(undefined);
        }
    }


    const columns: GridColDef[] = [

        { field: 'name', width: 500, headerName: 'Název', flex: 1 },
        {
            field: 'state', headerName: 'Stav', /* flex: 1, */ renderCell: (params: GridRenderCellParams) => (
                <div>
                    {params.row.state === 0 && <div>Nový</div>}
                    {params.row.state === 1 && <div>Aktivní</div>}
                    {params.row.state === 2 && <div>K revizi</div>}
                    {params.row.state === 3 && <div>Dokončeno</div>}
                    {params.row.state === 4 && <div>Uzavřeno</div>}
                </div>
            ),
        },
        {
            field: 'x', headerName: 'Editovat', width: 80, renderCell: (params: GridRenderCellParams) => (
                <Button onClick={() => { setUpdatingRoute(params.row); setUpdateOpen(true); }}>
                    <Edit />
                </Button>
            ), resizable: false
        },
        {
            field: 'y', headerName: 'Smazat', width: 80, renderCell: (params: GridRenderCellParams) => (
                <Button onClick={() => {
                    new ApiDataClient().DeleteRoute(parseInt(params.id.toString())).then((v) => {
                        reloadRoutes();
                    });
                }}>
                    <Delete />
                </Button>
            ), resizable: false
        },
    ];

    return (
        <div style={{ background: '#F5F5F5', display: 'flex' }}>           
            <div style={{ background: '#F5F5F5', width: '100%', marginTop: '5px' }}>
                {validator !== ValidatorsEnum.SlopeGuard && <div style={{ display: 'flex', justifyContent: 'left', marginTop: '32px', color: theme.palette.primary.main, fontSize: '22px', marginLeft: '32px', marginRight: 'auto', textAlign: 'left' }}>
                    <div>Trasy </div>
                    <div style={{ color: theme.palette.primary.main, fontSize: '22px', marginLeft: '32px', marginRight: 'auto', textAlign: 'left' }}>
                        <Button sx={{ minWidth: '160px', marginRight: 'auto' }} onClick={() => { setCreateOpen(true); }} size='small' variant='contained' startIcon={<Add />} >Přidat trasu</Button>
                    </div>
                </div>}
                {validator !== ValidatorsEnum.SlopeGuard && <div style={{ margin: '16px', marginTop: '4px', marginLeft: '16px' }}>
                    <DataGrid style={{ margin: '16px', marginTop: '4px', marginLeft: '16px' }} hideFooter rows={routes ? routes : []} columns={columns} hideFooterSelectedRowCount
                        onRowDoubleClick={(params) => {
                            if (validator === ValidatorsEnum.CurveSolver)
                                navigate('/solverMain/' + params.row.id);
                            /* else if (validator === ValidatorsEnum.SlopeGuard)
                                navigate('/slopeguard' + params.row.id); */
                        }}
                        sx={{
                            '& .MuiDataGrid-cell': {
                                background: 'white'
                            },
                        }}
                    />
                </div>}               
            </div> 
            {projectId && <CreateRouteDialog projectId={projectId} open={createOpen} onClose={() => { setCreateOpen(false); }} onRefresh={() => { reloadRoutes(); }} />}
            {updatingRoute && <UpdateRouteDialog route={updatingRoute} open={updateOpen} onClose={() => { setUpdateOpen(false); setUpdatingRoute(undefined); }} onRefresh={() => { reloadRoutes(); }} />}           
        </div>)
}
